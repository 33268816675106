












































import { Component, Prop, Vue } from "vue-property-decorator";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import DocumentService from "@/services/DocumentService";
import { processError } from "@/utils/ComponentUtils";
import { namespace } from "vuex-class";
import InsuranceSettingsDTO from "@/dto/archive/insurance/InsuranceSettingsDTO";

const AppModule = namespace("App");
const Insurance = namespace("Insurance");

@Component({
  components: { PortalCheckbox }
})
export default class InsuranceSettings extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Insurance.State
  private settings!: InsuranceSettingsDTO;

  @Insurance.Mutation
  private setSettings!: (newSettings: InsuranceSettingsDTO) => void;

  @Prop({ default: null })
  private wsId!: string;

  loadInsuranceSettings(): void {
    this.startLoading();
    DocumentService.getInsuranceSettings(Number(this.wsId)).then(
      resp => {
        this.setSettings(resp.data)
        this.stopLoading();
      },
      error => {
        processError(error, this);
        this.stopLoading();
      }
    )
  }

  setInsuranceSettings(): void {
    this.startLoading();
    DocumentService.saveInsuranceSettings(Number(this.wsId), this.settings).then(
      resp => {
        this.stopLoading();
        this.loadInsuranceSettings();
      },
      error => {
        processError(error, this);
        this.stopLoading();
      }
    )
  }

}
